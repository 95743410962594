import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0,3,4];

export const dictionary = {
		"/(app)": [5,[2]],
		"/admin": [~22],
		"/(app)/(business)/calendar/[[date]]": [~6,[2,3]],
		"/(app)/(business)/dashboard": [~7,[2,3]],
		"/(app)/(business)/establishment-setup": [~8,[2,3]],
		"/forgotten-password": [23],
		"/join/[[businessKey]]/[[joinHash]]/[[emailHash]]": [~24],
		"/login": [~25],
		"/logout": [~26],
		"/new-establishment": [27],
		"/(app)/(business)/notifications": [9,[2,3]],
		"/(app)/(business)/onboarding": [~10,[2,3]],
		"/(app)/(business)/pending-join-request": [~11,[2,3]],
		"/profile": [~28],
		"/register": [~29],
		"/reset-password/[[token]]": [~30],
		"/(app)/(business)/settings": [12,[2,3,4]],
		"/(app)/(business)/settings/general": [13,[2,3,4]],
		"/(app)/(business)/settings/notifications": [14,[2,3,4]],
		"/(app)/(business)/settings/plans": [15,[2,3,4]],
		"/(app)/(business)/settings/tags": [16,[2,3,4]],
		"/(app)/(business)/settings/templates": [~17,[2,3,4]],
		"/(app)/(business)/settings/worker-profile": [~18,[2,3,4]],
		"/(app)/(business)/team": [~19,[2,3]],
		"/(app)/unverified": [~20,[2]],
		"/verify/[hash]": [~31],
		"/[...path]": [~21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';