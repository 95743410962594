import * as Sentry from '@sentry/sveltekit'
import initApp from '$lib/init'
import { PUBLIC_NODE_ENV } from '$env/static/public'
import { currentBusiness, currentUser } from './lib/stores'
import { getUserDisplayName } from './lib/utils'

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: 'https://47253309b102b10509359d883bb85b9f@o4507272918728704.ingest.de.sentry.io/4507272929411152',
	tracesSampleRate: 0, // only for error logging for now, no profiling needed
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [Sentry.replayIntegration()],
	environment: PUBLIC_NODE_ENV,
	enabled: PUBLIC_NODE_ENV !== 'development'
})

currentBusiness.subscribe((business) => {
	Sentry.setContext('business', { id: business.id, name: business.name })
})

currentUser.subscribe((user) => {
	Sentry.setUser(user ? { id: user.id, username: getUserDisplayName({ user }) } : null)
})

initApp()

export const handleError = Sentry.handleErrorWithSentry()
